.spotCategoryContainer {
  display: flex;
  flex-wrap: wrap;
  font-size: small;
  margin: 5px;
}

.spotCategoryContainer .spotCategory {
  border: solid #f1c40f 1px;
  border-radius: 4px;
  margin-right: 3px;
  margin-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
}

.spotCategoryContainer .spotCategory.editable{
  cursor: pointer;
}

.spotCategoryContainer .spotCategory.selected {
  background-color: #f1c40f;
}

.spotCategoryContainer .spotCategory span {
  white-space: nowrap;
}
