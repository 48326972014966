@import './Input.css';
@import './SpotInfoBox.css';
@import './SpotCategory.css';

body, html {
  height: 100%;
  width: 100%;
  margin: 0;

  /*Default settings regarding style for whole app: */
  background-color: #2B2A33;
  color:rgb(244, 244, 244);
  font-family: Inter var,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.site { /* Don't know why, but is needed such that navigation gets displayed */
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
a:link, a:visited, a:active {
  color: #f1c40f;
  transition: color .15s ease-in-out
}
a:hover {
  color: #f39c12;
}
h3 {
  margin-left: 5px;
}
p {
  margin-left: 5px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/*The following is to style the menu bar: */
.menuBar {
  background-color: #1C1B22;
  height: 50px;
  margin: 0;
  padding: 0;
  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}
.leftArea {
  position: absolute;
  left: 0;
}
.pageTitle {
  font-size: 25px;
  margin: 8px;
}
.pageTitleLink, .pageTitleLink:link, .pageTitleLink:visited, .pageTitleLink:hover, .pageTitleLink:active {
  text-decoration: none;
  color: white;
}
.middleArea {
  margin: auto;
  top: 0;
  text-align: center;
  height: 100%;
}
.rightArea {
  position: absolute;
  right: 0;
  top: 0;
}

.mapPane {
  width: 100%;
  height: calc(100vh - 50px);
  z-index: 1; /* z-index is needed such that navigation gets displayed above. */
}
.leaflet-container {
  width: 100%;
  height: 100%;
}

.locButton {
  background-color:#1c1b22;
  border: 2px solid transparent;
  border-radius: 4px;
  color: #f1c40f;
  border-color: black;

  padding:0%;
  width: 34px;
  height: 34px;

  transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.locButton:hover {
  background-color: #2B2A33;
  cursor: pointer;
}
.locationIcon {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.textButton {
  cursor: pointer;
  outline: 0;

  background-color: #f1c40f;
  border-radius: .25rem;
  border-color: #f1c40f;
  border: 1px solid transparent;
  margin: 5px;
  padding: 6px 12px;

  display: inline-block;

  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px; /*18 was perfect for menubar*/
  text-align: center;

  transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.textButton:hover {
  background-color: #f39c12;
  border-color: #f39c12;
}

.iconButton {
  cursor: pointer;
  outline: 0;
  background-color: #f1c40f;
  border-radius: .25rem;
  border-color: #f1c40f;
  border: 1px solid transparent;
  margin: 5px;
  padding: 3px 6px;

  transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.iconButton:hover {
  background-color: #f39c12;
  border-color: #f39c12;
}

.navBarIcon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  color: white;
}

.navigation {
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #2B2A33;
  z-index: 4;
}

.formBox {
  position: relative;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #1C1B22;
  max-width: 340px;
  border: 1px solid black;
  border-radius: .75rem;
  padding: 10px;
}

.formBox.addSpotFlow {
  position: absolute;
  z-index: 2;
  bottom: 5px;
  left: 0;
  right: 0;

  max-height: calc(100vh - 95px); /* Set maximum height if overflowing due to content */
  overflow-y: auto; /* Makes div scrollable inside */

  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.formBox.reportSpot {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 5px;
}

.closeFormBox {
  position: absolute;
  right: 10px;
}

.closeCross {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  color: #f1c40f;
  transition: color .15s ease-in-out;
}

.closeCross:hover {
  color: #f39c12;
  cursor: pointer;
}

.errorPage {
  text-align: center;
}

.h3ReducedMargin {
  margin-top: 2px;
  margin-bottom: 2px;
}

.profileBox {
  margin-top: 20px;
  text-align: center;
}
.profilePicture {
  width: 80px;
  height: 80px;
  vertical-align: middle;
  color: white;
}
.menuBox {
  background-color: #1C1B22;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 338px;
  border: 1px solid black;
  border-radius: .75rem;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menuBox button {
  width: 100%;
  background-color: #1C1B22;
  border: 1px solid transparent;
  border-radius: .25rem;
  margin: 0px;
  padding: 6px 12px;

  display: inline-block;

  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px; /*18 was perfect for menubar*/
  text-align: left;

  transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.menuBox button:hover {
  background-color: #2B2A33;
  border-color: #2B2A33;
}
.menuBoxIcon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  color: white;
}
.menuBoxText {
  margin-left: 20px;
}

.textBox {
  position: relative;
  margin: auto;
  max-width: 900px;
  padding: 10px;
}

.errorBox {
  margin: 10px 5px;
  background-color: rgba(255, 10, 10, 0.3);
  max-width: 340px;
  border: 1px solid rgba(255, 10, 10, 1);
  border-radius: .75rem;
  padding: 10px;
}

.spotImage {
  max-width: 100%;
  height: 300px;
  display: block;
  margin: auto;
  padding-top: 10px;
}

.spotImageLoader {
  height: 300px;
  padding-top: 10px;
  display: flex;
  align-Items: center;
  justify-content: center;
}

.logo {
  width: 50px;
  border: 1px solid black;
  border-radius: .55rem;
  background-color: black;
}

.navigationFooter {
  text-align: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
}



/* Fixes because this css overrides some css of other components */

.leaflet-control-geosearch { /* Needed as html overrides css of searchbar */
  color: #fff!important;
}

.leaflet-container .leaflet-control-attribution {
  background: #2B2A33!important;
  color:#fff!important;
}

.leaflet-container a {
  color: #f1c40f!important;
}

.leaflet-bar a {
  color: #f1c40f!important;
  border-bottom: 2px solid black!important;
  background-color: #1C1B22!important;

  transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.leaflet-bar a:hover {
  background-color: #2B2A33!important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-geosearch-bar form {
  border: 2px solid transparent;
  border-radius: 4px;
  border-color: black!important;

  background-color: #1C1B22!important;
}

.leaflet-control-geosearch .results {
  background: none!important;
}

.leaflet-geosearch-bar form input {
  background-color: #2B2A33!important;
  color: #fff !important;;
}

.leaflet-control-geosearch button.reset {
  background-color: #1C1B22!important;
  color: #f1c40f!important;

  transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.leaflet-control-geosearch button.reset:hover {
  background-color: #2B2A33!important;
}

.leaflet-control-geosearch .results > .active, .leaflet-control-geosearch .results > :hover {
  background-color: #2B2A33!important;
  border-color: #2B2A33!important;
}

.login-animation{
  margin-left: 8px;
}
