.textInput {
  width: 95%;
  max-width: 400px;
  margin: 5px;
  border: 1px solid #f1c40f;
  border-radius: .25rem;
  background-color: #2B2A33;
  color: #fff;
  padding: .375rem .75rm;
  line-height: 1.5;
  font-size: 1rem;
}

.passwordContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.passwordContainer .passwordVisibilityIcon {
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
}
